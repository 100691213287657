import React, {useEffect} from 'react';
import { connect } from 'react-redux'
import {
    isAndroid,
    isIOS
  } from "react-device-detect";

const MobileDownload = (props) => {
    useEffect(() => {
        if (isAndroid) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.enqbator.ezdoc";
        }else if(isIOS) {
          window.location.href =
            "https://apps.apple.com/lk/app/ezdoc/id1524014547";
        } else{
          window.location.href =
            "https://ezdoc.lk/#/";
        }
      }, []);

    return (
        null
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileDownload)
