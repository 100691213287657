import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Box, Container, Grid } from '@material-ui/core'

import CallNowFooterSticky from 'components/widgets/CallNowFooterSticky'
import { useDispatch } from 'react-redux'

import * as appActions from '../store/AppStore/appActions'

const useStyles = makeStyles(theme => ({
	root: {
		//flexGrow: 1,
		position: 'relative',
		// border: '1px solid red',
		height: 'auto',
		width: '100%'
	},
	footerWrapper: {
		position: 'relative',
		margin: '0',
		padding: '0',
		height: '100px',
		width: '100%',
		boxSizing: 'border-box',
		backgroundColor: '#0e2c39',
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	footerBox: {
		color: '#fff',
		fontSize: '16px', //'calc(13px, 1.0vw, 14px)',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			fontSize: '13px'
		}
	},
	leftFooterBlock: {
		fontFamily: 'Roboto Bold,sans-serif',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	rightFooterBlock: {
		fontFamily: 'Roboto Light,sans-serif',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	infoBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '0px dotted #ddd',
		[theme.breakpoints.up('md')]: {
			//textAlign: 'left',
		},
		'&.divider': {
			borderLeft: '2px solid #f5f5f5',
			textAlign: 'center',
			paddingLeft: '3em',
			marginLeft: '3em'
		}
	}
}))

const AppFooter = props => {
	const { history } = props
	const [show, setShow] = useState(true)
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	useEffect(() => {
		const path = history.location.pathname
		setShow(
			path === '/' ||
				path === '/contact' ||
				path === '/dispensary/login' ||
				path === '/dispensary/portal-web'
		)
	})

	const dispatch = useDispatch()

	const showFaq = () => {
		const payload = {
			show: true,
			content: 'FAQ'
		}
		dispatch(appActions.showModal(payload))
	}

	const showTnC = () => {
		const payload = {
			show: true,
			content: 'TnC'
		}
		dispatch(appActions.showModal(payload))
	}

	return show ? (
		<Box component='div' className={classes.root}>
			<Box component='div' className={classes.stickyBox}>
				<CallNowFooterSticky history={history} />
			</Box>
			{/* sticky end */}
			<Box component='div' className={`${classes.footerWrapper} footer_wrapper`}>
				<Container maxWidth='lg' disableGutters={false} component='div'>
					<Grid container spacing={2} justifyContent='space-around' className={classes.footerBox}>
						<Grid item xs={12} sm={6} md={6} lg={6} className={classes.leftFooterBlock}>
							<div className={classes.infoBox}>
								<div className={classes.infoBox} onClick={showTnC}>
									Terms & Conditions
								</div>
								<div className={`${classes.infoBox} divider`} onClick={showFaq}>
									FAQ
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6} className={classes.rightFooterBlock}>
							<div className={classes.infoBox}>
								<span className=''>
									&copy; {new Date().getFullYear()} ezDOC | Powered by ENQBATOR (PVT) LTD.
								</span>
							</div>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Box>
	) : null
}

export default withRouter(AppFooter)
