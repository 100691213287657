import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { Box, Container, Grid } from '@material-ui/core'

import FormHeader from 'containers/forms/components/FormHeader'

const styles = theme => ({
	root: {
		flex: 1,
		border: '1px solid #eee',
		borderWidth: '1px 1px 0 1px',
		// boxShadow: '2px 5px 10px #eee',
		minHeight: 'calc(100vh - 0.233rem)',
		[theme.breakpoints.between('md', 'lg')]: {
			zoom: '0.75',
			minHeight: `calc(100vh + calc(100vh * .33))`
		}
	},
	formOverlay: {
		margin: '0',
		padding: '0',
		marginTop: '-4rem',
		backgroundColor: '#FFFFF'
	}
})

class FormWizardLayout extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			classes,
			theme,
			children,
			showHeading,
			mainHeading,
			subHeading,
			exitForm,
			prevStep,
			stepper,
			prevArrow,
			...rest
		} = this.props

		return (
			<Box component='div' className={classes.formOverlay}>
				<Container className={classes.root} maxWidth='md' disableGutters={true} component='div'>
					<FormHeader
						show={showHeading}
						prevArrow={prevArrow}
						stepper={stepper}
						mainHeading={mainHeading}
						subHeading={subHeading}
						exitForm={exitForm}
						prevStep={prevStep}
					/>
					<Box component='div'>{children}</Box>
				</Container>
			</Box>
		)
	}
}

export default withStyles(styles, { withTheme: true })(FormWizardLayout)
