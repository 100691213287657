import * as types from './types'

const defaultState = {
	step: 1,
	title: 'Register a Dispensary',
	conditionTerms: false,
	formInputs: {
		firstName: '',
		lastName: '',
		email: '',
		emailConfirm: '',
		password: '',
		dispensaryName: '',
		businessRegNo: '',
		address: '',
		telephone: '',
		serviceCategory: '',
		cardReader: '',
		otp: '',
		location: {}
	},
	formHints: {
		dispensaryName: ''
	},
	createDispensaryOwnerResponse: {
		uuid: '',
		status: '',
		from: ''
	},
	confirmDispensaryOwnerResponse: {
		uuid: '',
		status: '',
		form: ''
	},
	flowless: false,
	registerDispensaryResponse: {},
	tempDispensary: ''
}

const dispensaryReducer = (state = defaultState, action) => {
	switch (action.type) {
		case types.SET_FORM_FIELD_VALUE:
			return {
				...state,
				formInputs: {
					...state.formInputs,
					[action.payload.fieldname]: action.payload.value
				}
			}

		case types.SET_FORM_FIELD_HINT:
			return {
				...state,
				formHints: {
					...state.formHints,
					[action.payload.fieldname]: action.payload.value
				}
			}
		case types.SET_LOCATION_AND_ADDRESS: {
			return {
				...state,
				formInputs: {
					...state.formInputs,
					address: action.payload.address,
					location: action.payload.location
				}
			}
		}
		case types.SET_DISPENSARY_DETAILS_TO_FORM:
			return {
				...state,
				formInputs: action.payload
			}

		case types.UPDATE_CURRENT_FORM_STEP:
			return {
				...state,
				step: action.payload
			}

		case types.UPDATE_CONDITION_AND_TERMS:
			return {
				...state,
				conditionTerms: action.payload
			}
		case types.AWS_CREATE_DISPENSARY_OWNER_ACCOUNT_RESPONSE:
			return {
				...state,
				createDispensaryOwnerResponse: action.payload
			}
		case types.AWS_CONFIRM_OTP_RESPONSE:
			return {
				...state,
				confirmDispensaryOwnerResponse: action.payload
			}
		case types.FLOWLESS_REGISTRATION:
			return {
				...state,
				flowless: action.payload
			}
		case types.CREATE_DISPENSARY_RESPONSE:
			return {
				...state,
				createDispensaryResponse: action.payload
			}
		case types.SET_TEMP_DISPENSARY:
			return {
				...state,
				tempDispensary: action.payload
			}
		case types.CLEAR_TEMP_DISPENSARY:
			return {
				...state,
				tempDispensary: ''
			}
		default:
			return state
	}
}

export default dispensaryReducer
