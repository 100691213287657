// import createReducer from '../../../helper/createReducer';
import { getUpdatedAppoiments } from 'utils';
import * as types from './types';

const defaultState = {
    appointments: [],
    // selectedPatient: {},
    // responseSearchPatient: {},
    // responseAddNewPatient: {},
    // bookAppointmentResponse: {},
    // responseAppointmentStatusUpdate: {},
    // responseCancelAppointment: {},
    // modifySlot: {}
};

const appointmentsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.RESPONSE_APPOINTMENTS:
            return {
                ...state,
                appointments: [...action.payload]
            };
        case types.RESPONSE_BOOK_APPOINTMENT:
            return {
                ...state,
                lastBookAppointment: { ...action.payload }
            };
        case types.UPDATE_BOOKED_SLOT:
            const { payload: doctorUpdatedSlot } = action
            const updatedAPoiments = getUpdatedAppoiments(state, doctorUpdatedSlot, true)
            return {
                ...state,
                appointments: updatedAPoiments,
            }
        case types.KIOSK_FIRST_NAME:
            const { payload: firstName } = action
            return {
                ...state,
                firstName: firstName
            }
        case types.UPDATE_DOCTOR_LIST:
            return {
                ...state,
                doctors: { ...action.payload }
            }
        default:
            return state
    }
}

export default appointmentsReducer