import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { Box, Container, Grid } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { ArrowBack, Close } from '@material-ui/icons'
import Controls from 'containers/forms/components/controls'
import Widgets from 'components/widgets'
import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { SET_LOGIN, SET_EMAIL, SET_EMAIL_AND_PASSWORD, SET_PASSWORD } from 'containers/forms/auths/AuthEvents'

const useStyles = makeStyles(theme => ({
	authFormBox: {
		width: '100%',
		margin: `${theme.spacing(0)}rem ${theme.spacing(0)}rem`
	},

	formCtrlBox: {
		margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`
	},
	formCtrlRetype: {
		margin: `${theme.spacing(5)}px ${theme.spacing(0)}px`
	},
	headingBox: {
		padding: '2rem 1rem',
		margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
		fontSize: 'clamp(20px, calc(1vw + 0.7rem), 30px)',
		fontFamily: 'Roboto Bold ,sans-serif',
		color: '#0E2C39',
		textTransform: 'uppercase',
		[theme.breakpoints.down('xs')]: {
			margin: `${theme.spacing(0)}px ${theme.spacing(0)}px`
		}
	},
	eventHeadingBox: {
		// border: '1px dotted pink',
		padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
		marginBottom: theme.spacing(5),
		fontSize: 'clamp(16px, calc(1vw + 0.5rem), 20px)',
		fontFamily: 'Roboto Light ,sans-serif',
		color: '#0E2C39',
		opacity: '0.5',
		textTransform: 'initial',
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2),
			padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
			fontFamily: 'Roboto Bold ,sans-serif',
			fontSize: '16px',
			color: '#0E2C39',
			opacity: '1'
		}
	},
	formsBody: {
		//border: '1px dotted green',
		width: '100%',
		margin: `${theme.spacing(0)}px ${theme.spacing(0)}px`,
		//padding: '1rem 1rem',
		'& .MuiFormControl-root': {
			width: '100%',
			marginBottom: theme.spacing(0)
		}
	},
	formFooterText: {
		display: 'flex',
		flexDirection: 'row',
		fontSize: 'clamp(15px, calc(1vw + 0.6rem), 20px)', // 20px
		fontFamily: 'Roboto Medium ,sans-serif',
		padding: `${theme.spacing(7)}px ${theme.spacing(0)}px`,
		color: '#0E2C39',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'flex-start'
		}
	},
	formFooterTextLeft: {
		color: '#0E2C39'
	},
	formFooterTextRight: {
		color: '#39CFBA',
		[theme.breakpoints.down('xs')]: {
			//fontSize: '20px', // 20px
			fontSize: 'clamp(18px, calc(1vw + 0.6rem), 20px)'
		}
	},

	formCtrlHelpBox: {
		marginTop: theme.spacing(-1.5),
		marginBottom: theme.spacing(2),
		fontSize: 'clamp(14px,calc(1vw + 0.5rem),14px)', // 20px
		fontFamily: 'Roboto Medium ,sans-serif'
		//padding: `${theme.spacing(-1)}px ${theme.spacing(0)}px`,
	},
	formCtrlHelpLink: {
		//fontFamily: 'Roboto Medium,sans-serif',
		//fontSize: 'clamp(15px,2vw,20px)',
		color: '#39CFBA',
		cursor: 'pointer',
		textDecoration: 'none'
	}
}))

const AuthForm = props => {
	const classes = useStyles()
	const theme = useTheme()
	const {
		formEvent,
		buttonLabel,
		buttonEventType = 'submit',
		data,
		nextAuthEvent,
		handleSubmit,
		handleInputChange,
		eventHeading = '',
		requestOTPAws,
		submitOtpValidation
	} = props

	const [error, setError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')

	function validateEmail(email) {
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
			setErrorMsg('Invalid Email')
			setError(true)
		} else {
			setErrorMsg('')
			setError(false)
		}
	}

	function nextAuth(e) {
		if (formEvent === 'SET_EMAIL') {
			validateEmail(data.email)
			if (!error) {
				requestOTPAws()
			}
			// nextAuthEvent()
		} else if (formEvent === 'SET_PASSWORD') {
			e.preventDefault()
			submitOtpValidation()
		}
	}

	return (
		<Box
			component='div'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			className={classes.authFormBox}>
			<Grid container>
				<Grid item xs={12}>
					{formEvent === SET_LOGIN ? (
						<Box
							component='div'
							display='flex'
							flexDirection='column'
							justifyContent='center'
							alignItems='center'
							className={classes.headingBox}>
							Login
						</Box>
					) : (
						<Box
							component='div'
							display='flex'
							flexDirection='column'
							justifyContent='center'
							alignItems='center'
							className={classes.eventHeadingBox}>
							{eventHeading}
						</Box>
					)}
				</Grid>
				<Grid item xs={12}>
					<form onSubmit={handleSubmit} autoComplete='off'>
						<Box
							component='div'
							display='flex'
							flexDirection='column'
							justifyContent='center'
							className={classes.formsBody}>
							{/* ......login email input_box............. */}
							{formEvent !== SET_PASSWORD && (
								<Box className={classes.formCtrlBox}>
									<Controls.InputText
										label='Email Address'
										type='email'
										name='email'
										value={data.email}
										onChange={handleInputChange}
										error={errorMsg}
									/>
								</Box>
							)}

							{/*.......login password input_box.......... */}
							{formEvent !== SET_EMAIL && (
								<Box className={classes.formCtrlBox}>
									<Controls.InputPasswordText
										label={`${formEvent === SET_PASSWORD ? 'New Password' : 'Password'}`}
										name='password'
										value={data.password}
										onChange={handleInputChange}
										error={null}
									/>
								</Box>
							)}

							{formEvent === SET_PASSWORD && (
								<Box className={clsx(classes.formCtrlBox, classes.formCtrlRetype)}>
									<Controls.InputPasswordText
										label='Retype New Password.'
										name='retypePassword'
										value={data.retypePassword}
										onChange={handleInputChange}
										error={null}
									/>
								</Box>
							)}

							{formEvent === SET_LOGIN && (
								<Box
									className={classes.formCtrlHelpBox}
									component='div'
									display='flex'
									flexDirection='row'
									justifyContent='flex-end'>
									<Link
										to={NAVBAR_ROUTES.OWNER_LOGIN_RESET}
										className={classes.formCtrlHelpLink}>
										Forgotten Password?
									</Link>
								</Box>
							)}

							<Box
								className={classes.formCtrlBox}
								component='div'
								display='flex'
								flexDirection='row'
								justifyContent='center'>
								<Widgets.ButtonBox
									type={buttonEventType}
									label={buttonLabel}
									variant='contained'
									color='primary'
									size='large'
									onClick={nextAuth}
								/>
							</Box>
						</Box>
					</form>
					{/* end form section */}
				</Grid>
				{formEvent === SET_LOGIN && (
					<Grid item xs={12}>
						<Link to={NAVBAR_ROUTES.DESPENSARY_SIGNUP_FORM} className={classes.formCtrlHelpLink}>
							<Box
								component='div'
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								className={classes.formFooterText}>
								<span className={classes.formFooterTextLeft}>
									Don’t have an account yet?&nbsp;
								</span>
								<span className={classes.formFooterTextRight}>
									Register as a Dispensary Owner
								</span>
							</Box>
						</Link>
					</Grid>
				)}
			</Grid>
		</Box>
	)
}

export default AuthForm
