import React, { Component, Fragment } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import OtpInput from 'react-otp-input'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { Box, Container, Grid, Button } from '@material-ui/core'

import * as dipensaryActions from '../../Dispensary/redux/actions'
import * as doctorActions from '../../doctor/redux/actions'
import * as loginActions from '../../auths/redux/actionsResetPassword'
import * as staffActions from '../../Dispensary/redux/dispensaryStaffActions'
import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { FORM_DISPENSARY, FORM_DISPENSARY_STAFF, FORM_DOCTOR, RESET_PASSWORD } from 'constants/FormConstants'
import OTPFormHeader from '../widgets/OTPFormHeader'

const styles = theme => ({
	root: {
		flex: 1,
		border: '1px solid #f5f5f5', // 7cdcce
		minHeight: 'calc(100vh - 0.233rem)',
		backgroundColor: '#FFFFFF',
		[theme.breakpoints.between('md', 'lg')]: {
			zoom: '0.75',
			minHeight: `calc(100vh + calc(100vh * .33))`
		}
	},
	layoutOverlay: {
		backgroundColor: '#fafafa', // liner
		margin: '0',
		padding: '0',
		marginTop: '-4rem',
		boxSizing: 'border-box'
	},
	optContent: {
		boxSizing: 'border-box',
		textAlign: 'center',
		//border:'1px solid red',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: '0.02rem'
	},
	optGuidelineBox1: {
		fontFamily: 'Roboto Light,sans-serif',
		fontSize: 'clamp(18px,2.4vw,24px)',
		color: '#0E2C39',
		opacity: 0.7,
		padding: '5rem 0rem',
		lineHeight: '2.5rem'
	},
	optGuidelineBox2: {
		fontFamily: 'Roboto Light,sans-serif',
		fontSize: 'clamp(18px,2.2vw,20px)',
		color: '#0E2C39',
		opacity: 0.5,
		paddingTop: '5rem',
		lineHeight: '2.2rem'
	},

	optInputBox: {}
})

class FormOTPWizardLayout extends Component {
	constructor(props) {
		super(props)
		this.state = {
			otp: '',
			maxLen: 1,
			digitBox: [
				{ key: 0, disable: false, val: '', active: true },
				{ key: 1, disable: true, val: '', active: false },
				{ key: 2, disable: true, val: '', active: false },
				{ key: 3, disable: true, val: '', active: false },
				{ key: 4, disable: true, val: '', active: false },
				{ key: 5, disable: true, val: '', active: false }
			]
		}
	}

	componentDidMount() {}

	handleChange = otp => {
		this.setState({ otp: otp }, () => {
			if (this.state.otp.length === 6) {
				// this.props.sendCodeToVerify(this.state.otp);
				// Temp Code for check the flaw.
				if (this.props.formType === FORM_DISPENSARY) {
					// TODO CHATHURA
					const { email } = this.props.authState.tempUserInfo.attributes
					const payload = {
						username: email,
						code: this.state.otp,
						formType: FORM_DISPENSARY
					}
					this.props.dipensaryActions.awsConfirmUserRequest(payload)
				} else if (this.props.formType === FORM_DOCTOR) {
					const { email } = this.props.authState.tempUserInfo.attributes
					const payload = {
						username: email,
						code: this.state.otp,
						formType: FORM_DOCTOR
					}
					this.props.doctorActions.awsConfirmUserRequest(payload)
				} else if (this.props.formType === RESET_PASSWORD) {
					this.props.loginActions.setOtpValue(this.state.otp)
					this.props.loginActions.updateResetFormStep(3)
				} else if (this.props.formType === 'FROM_VALIDATE') {
					const { email } = this.props.authState.formInputs
					const payload = {
						username: email,
						code: this.state.otp,
						formType: 'FROM_VALIDATE'
					}
					this.props.dipensaryActions.awsConfirmUserRequest(payload)
				} else if (this.props.formType === FORM_DISPENSARY_STAFF) {
					const payload = {
						username: this.props.email,
						code: this.state.otp,
						formType: FORM_DISPENSARY_STAFF
					}
					this.props.staffActions.confirmStaffUserAwsRequest(payload)
				}
			}
		})
	}

	resendOtp = () => {
		const { authState, resetPasswordState } = this.props
		const email =
			this.props.formType === 'RESET_PASSWORD'
				? resetPasswordState.formInputs.email
				: this.props.formType === 'FROM_VALIDATE'
				? authState.formInputs.email
				: this.props.formType === FORM_DISPENSARY_STAFF
				? this.props.email
				: authState.tempUserInfo.attributes.email
		this.props.dipensaryActions.resendOtp(email)
	}

	render() {
		const { classes, theme, history, authState, resetPasswordState } = this.props

		return (
			<Box component='div' className={classes.layoutOverlay}>
				<Container className={classes.root} maxWidth='md' disableGutters={true} component='div'>
					<OTPFormHeader heading='Enter Code' formType={this.props.formType} />
					<Grid container spacing={3} justifyContent='center' className={classes.optContent}>
						<Grid item xs={10}>
							<div className={classes.optGuidelineBox1}>
								Confirm your email address by entering the 6-digit code sent <br />
								to{' '}
								{this.props.formType === 'RESET_PASSWORD'
									? resetPasswordState.formInputs.email
									: this.props.formType === 'FROM_VALIDATE'
									? authState.formInputs.email
									: this.props.formType === FORM_DISPENSARY_STAFF
									? this.props.email
									: authState.tempUserInfo.attributes.email}
							</div>
							<div className={`${classes.optInputBox} opt_input_box`}>
								<OtpInput
									isInputNum={true}
									value={this.state.otp}
									onChange={this.handleChange}
									numInputs={6}
									separator={<span></span>}
								/>
							</div>
							<div className={classes.optGuidelineBox2}>
								Didn’t get a code?
								<br /> No worries. Request a new one.
							</div>
						</Grid>
						<Grid item xs={10}>
							<div className={classes.footerBox}>
								<Button onClick={this.resendOtp}>
									<span className='brand_highlighted_text_light'>Send a code again</span>
								</Button>
							</div>
						</Grid>
					</Grid>
				</Container>
			</Box>
		)
	}
}

function mapStateToProps(state) {
	return {
		authState: state.authReducer,
		dispensaryState: state.dispensaryReducer,
		doctorState: state.doctorState,
		resetPasswordState: state.resetPasswordReducer
	}
}

function mapDispatchToProps(dispatch) {
	return {
		dipensaryActions: bindActionCreators(dipensaryActions, dispatch),
		doctorActions: bindActionCreators(doctorActions, dispatch),
		loginActions: bindActionCreators(loginActions, dispatch),
		staffActions: bindActionCreators(staffActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FormOTPWizardLayout))
