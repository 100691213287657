import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Container, Box } from '@material-ui/core'

import phoneImg from 'images/contact_imgs/telephone.svg'

const useStyles = makeStyles(theme => ({
	footerStickyBox: {
		borderRadius: '20px 25px 0px 0px',
		float: 'right',
		background: '#FEF9E9',
		//marginTop:'-5px',
		//marginTop: 'calc(100vh - calc(100vh * 0.81) )',
		//marginTop: '0.5rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',

		fontFamily: 'Roboto Medium ,sans-serif',
		color: '#000000',

		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			borderRadius: '20px 0px 0px 0px',
			flexDirection: 'column',
			right: '0px'
		}
	},
	lableLeft: {
		padding: `${theme.spacing(1)}px ${theme.spacing(0.2)}px`
	},
	lableRight: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		padding: `${theme.spacing(1)}px ${theme.spacing(0.2)}px`
	},
	phoneImg: {
		width: '36px',
		height: '36px',
		[theme.breakpoints.down('xs')]: {
			width: '25px',
			height: '25px',
			marginLeft: theme.spacing(2)
		}
	},
	phoneText: {
		fontSize: 'clamp(20px, 1vw, 25px)',
		padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`
	},
	contactText: {
		fontSize: 'clamp(15px, 1.2vw, 25px)',
		padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
		[theme.breakpoints.down('xs')]: {
			marginLeft: theme.spacing(5)
		}
	}
}))

const CallNowFooterSticky = props => {
	const classes = useStyles()
	const { history } = props
	const [show, setShow] = useState(true)

	useEffect(() => {
		const path = history.location.pathname
		setShow(path === '/dispensary/login' || path === '/dispensary/portal-web')
	})

	return show ? (
		<Box component='div' className={`${classes.footerStickyBox} footer_sticky_box`}>
			<div className={classes.lableLeft}>
				<span className={classes.contactText}>Need any help? Call us</span>
			</div>
			<div className={classes.lableRight}>
				<img src={phoneImg} alt='mobile' className={classes.phoneImg} />
				<span className={classes.phoneText}>+94 77 706 2733</span>
			</div>
		</Box>
	) : null
}

export default CallNowFooterSticky
