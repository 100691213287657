import React, { Fragment, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
	Box,
	Container,
	Button,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemIcon
} from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Grid from '@material-ui/core/Grid'
import patientMobile from '../images/patients_mobile.png'
import dispensaryMobile from '../images/dispensary_mobile.png'
import mutedPoster from '../images/flash_muted_poster.png'
import proLogo from '../images/ezdoc_pro_logo_white.png'
//import proLogo from '../images/ezdoc_logo_white(pro).png';
import defaultLogo from '../images/ezdoc_logo_lg.png'
import appleStoreLogo from '../images/mobile-app-store.png'
import googlePlayLogo from '../images/mobile-google-play.png'
import appleStoreLogo1 from '../images/mobile-app-store1.png'
import googlePlayLogo1 from '../images/mobile-google-play1.png'

import SignupPostCard from '../components/widgets/SignupPostCard'
import BenefitsListCard from '../components/widgets/BenefitsListCard'
import Widgets from 'components/widgets'
import { NAVBAR_ROUTES } from 'constants/NavConstants'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as dispensaryActions from '../containers/forms/Dispensary/redux/actions'
import * as doctorActions from '../containers/forms/doctor/redux/actions'
import {
    isAndroid,
    isIOS
  } from "react-device-detect";

// bullet: {
//   display: 'inline-block',
//     margin: '0 2px',
//       transform: 'scale(0.8)',
//   },

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,

		[theme.breakpoints.up('lg')]: {
			maxWidth: '1300px'
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		// TODO zoom
		[theme.breakpoints.between('md', 'lg')]: {
			//backgroundColor:'red',
			zoom: '0.87'
		}
	},

	bannerRowBox: {
		backgroundSize: 'contain',
		[theme.breakpoints.only('md')]: {
			backgroundSize: 'cover'
		},
		[theme.breakpoints.only('xs')]: {
			backgroundSize: '65vh'
		}
	},

	topHeaderTitle: {
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			width: '160px',
			letterSpacing: '0px'
		}
	},
	bannerBulletList: {
		paddingBottom: '1em',
		[theme.breakpoints.down('sm')]: {
			marginTop: '10em'
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '1em'
		}
	},

	topBannerButtonBox: {
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		padding: '1em 1em 2em 1em',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			alignItems: 'center'
		}
	},

	logoBoxIcons: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'row',
			alignItems: 'flex-end',
			paddingBottom: '2em'
			//paddingTop: '-1em'
		}
	},
	iconTop: {
		width: '263px',
		height: 'auto',
		'&.pro': {
			// width: '263px',
			// height: 'auto',
		},
		'&.default': {
			// marginTop: '1.5em',
			//marginBottom: '2.5em',
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: '1em',
			width: '100px',
			margin: '0em 0em',
			'&.pro': {
				width: '125px'
			},
			'&.default': {
				width: '125px',
				marginBottom: '0.5em'
			}
		}
	},

	googleImageLink: {
		marginBottom: theme.spacing(1),
		'&:hover': {
			filter: 'drop-shadow(0 0 0.60rem #BBB)'
		}
	},
	appleImageLink: {
		marginTop: theme.spacing(1),
		'&:hover': {
			filter: 'drop-shadow(0 0 0.60rem #BBB)'
		}
	},

	iconBottom: {
		width: '300px',
		height: 'auto',
		marginBottom: '5em',
		'&.default': {
			//marginTop: '1em',
		},

		[theme.breakpoints.down('sm')]: {
			marginLeft: '1em',
			width: '150px',
			margin: '0em 0em'
		}
	},

	/**
	 * ----------------------------------------------
	 */
	patientLeft: {
		order: 1,
		[theme.breakpoints.up('md')]: {
			//order: 2,
		}
	},

	patientRight: {
		order: 2,
		[theme.breakpoints.up('md')]: {
			//order: 1,
		}
	},

	patientImgHand: {
		paddingTop: '-1em',
		marginLeft: '-12em',
		width: '100%',
		height: 'auto',
		[theme.breakpoints.down('md')]: {
			marginLeft: '-8.5em'
		},

		[theme.breakpoints.only('sm')]: {
			marginLeft: '-5em', // 25
			width: '70%'
		}
	},

	mainHeadingBlock: {
		textAlign: 'right',
		letterSpacing: '-0.3px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			letterSpacing: '0px',
			margin: '1em 0em'
		}
	},

	/**
	 * ----------------------------------------------
	 */

	dispensaryLeft: {
		order: 2,
		[theme.breakpoints.down('sm')]: {
			order: 1
		}
	},
	dispensaryRight: {
		order: 1,
		[theme.breakpoints.down('sm')]: {
			order: 2
		}
	},

	dispensaryImgHand: {
		//paddingTop: '-1em',
		marginRight: '-3em',
		width: '100%',
		height: 'auto',
		[theme.breakpoints.down('md')]: {
			//marginLeft: '-8.5em',
		},

		[theme.breakpoints.only('sm')]: {
			//marginLeft: '-5em', // 25
			width: '70%'
		}
	},

	signupBottomLeft: {
		height: '212px',
		backgroundColor: '#ccc',
		marginBottom: '0.5em',

		'&:after': {
			borderColor: 'rgba(255, 255, 255, 0.2)',
			borderLeftColor: '#f5f5f5', //f30000
			borderWidth: '107px',
			marginTop: '-107px'
		},

		[theme.breakpoints.between('xs', 'sm')]: {
			height: '300px',
			marginBottom: '-2em'
		},

		[theme.breakpoints.between('md', 'lg')]: {
			'&:after': {
				marginTop: '-108px'
			}
		},
		[theme.breakpoints.only('md')]: {
			'&:after': {
				marginTop: '-118px'
			}
		},
		[theme.breakpoints.down('sm')]: {
			'&:after': {
				display: 'none'
			}
		}
	},

	mainBanner: {
		width: '100%',
		height: 'auto',
		position: 'relative'
	},

	bannerImage: {
		width: '100%',
		height: 'auto',
		position: 'absolute',
		top: '0',
		left: 0,
		flexGrow: 1,
		[theme.breakpoints.up('sm')]: {
			//minHeight: 'calc(100% + 0rem)',
			//minWidth: 'calc(100% + 10rem)'
		}
	},
	bannerInfoBlock: {
		flex: 1,
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		position: 'relative',
		border: '0px dotted blue'
	},
	bulletIcon: {
		transform: 'scale(1)',
		color: '#39cfba'
	}
}))

const Home = props => {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const auth = useSelector(state => state.authReducer.loggedIn)

	const { history } = props

	useEffect(() => {
		if (auth) {
			// history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
		}
		// if (isAndroid) {
		// 	window.location.href =
		// 	  "https://play.google.com/store/apps/details?id=com.enqbator.ezdoc";
		//   }else if(isIOS) {
		// 	window.location.href =
		// 	  "https://apps.apple.com/lk/app/ezdoc/id1524014547";
		//   } else{
		// 	window.location.href =
		// 	  "https://ezdoc.lk/#/";
		//   }
	})

	function routeToSignup() {
		history.push(NAVBAR_ROUTES.DESPENSARY_SIGNUP_FORM)
	}

	return (
		<Fragment>
			<Box component='div' className={`${classes.bannerRowBox} banner_row_box row_box`}>
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={0} justifyContent='center'>
							<Grid item xs={12}>
								<div className={classes.mainBanner}>
									{/* <img src={banners} alt="banner" className={classes.bannerImage} /> */}
									<div className={classes.bannerInfoBlock}>
										{/* banner top heading text */}
										<div className={`${classes.topHeaderTitle} heading_H1 top_header`}>
											Digitise your Dispensary with{' '}
											<span className='brand_highlighted_text'>ezDOC</span>
										</div>
										{/* banner bottom text */}
										<div>
											{/* banner info list */}
											<Box className={classes.bannerBulletList}>
												<List>
													<ListItem>
														<ListItemIcon>
															<CheckCircleIcon className={classes.bulletIcon} />
														</ListItemIcon>
														<ListItemText id='label-item-1'>
															<span className='heading_H4'>
																Patients can see Doctor's availability on
																ezDOC app
															</span>
														</ListItemText>
													</ListItem>

													<ListItem>
														<ListItemIcon>
															<CheckCircleIcon className={classes.bulletIcon} />
														</ListItemIcon>
														<ListItemText id='label-item-2'>
															<span className='heading_H4'>
																Patients book their appointments Online in
																advance
															</span>
														</ListItemText>
													</ListItem>

													<ListItem>
														<ListItemIcon>
															<CheckCircleIcon className={classes.bulletIcon} />
														</ListItemIcon>
														<ListItemText id='label-item-3'>
															<span className='heading_H4'>
																No Long Queues &#8594; Social-Distancing at
																Dispensaries
															</span>
														</ListItemText>
													</ListItem>
													<ListItem>
														<ListItemIcon>
															<CheckCircleIcon className={classes.bulletIcon} />
														</ListItemIcon>
														<ListItemText id='label-item-4'>
															<span className='heading_H4'>
																Zero cost for Dispensaries
															</span>
														</ListItemText>
													</ListItem>
												</List>
											</Box>

											<Box className={classes.topBannerButtonBox}>
												<Widgets.ButtonBox
													type='button'
													disabled={false}
													label='SignUp Now'
													variant='contained'
													color='primary'
													size='large'
													onClick={routeToSignup}
												/>
												{/* <Button
                          variant="contained"
                          color="primary"
                          size="large">
                          SIGNUP NOW
                        </Button> */}
											</Box>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			</Box>

			{/* signup heading container */}

			<Box component='div' className='signup_row_box row_box'>
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={0} justifyContent='center'>
							<Grid item xs={12} className='signup_headline_text'>
								<span className='w-text'>Now open</span> to{' '}
								<span className='w-text'>EARLY-SIGNUP</span> with{' '}
								<span className='brand_highlighted_text'>ezDOC</span> in 2 easy steps
							</Grid>
						</Grid>
						<Grid container spacing={10} justifyContent='space-between'>
							<Grid item xs={12} sm={12} md={8} lg={8}>
								{/* <div> */}
								<Grid
									container
									spacing={3}
									justifyContent='center'
									className={`${classes.signupBottomLeft} signup_bottom_left`}>
									<Grid item xs={12} sm={12} md={5} lg={5} className='signup_card'>
										<SignupPostCard id={1} />
									</Grid>
									<Grid item xs={12} sm={12} md={5} lg={5} className='signup_card'>
										<SignupPostCard id={2} />
									</Grid>
								</Grid>
								{/* </div> */}
							</Grid>
							{/* ------------- divider space ------------ */}
							<Grid item xs={12} sm={12} md={3} lg={3}>
								<div
									style={{
										height: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}>
									<Widgets.ButtonBox
										type='button'
										disabled={false}
										label='SignUp Now'
										variant='contained'
										color='primary'
										size='large'
										onClick={routeToSignup}
									/>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			</Box>

			{/* flash video container */}
			<Box component='div' className='video_row_box row_box'>
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={0} justifyContent='center'>
							<Grid item xs={12}>
								<div className=''>
									<video controls autoPlay muted volume='0.5' width='100%' height='90%'>
										<source
											id='mp4'
											src='https://ezdoc-contents.s3.ap-south-1.amazonaws.com/ezDOC.mp4'
											type='video/mp4'
										/>
									</video>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			</Box>

			{/* patient_view container */}

			<Box component='div' className='patient_row_box row_box'>
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={1} justifyContent='space-evenly'>
							<Grid item xs={12} sm={12} md={5} lg={5} className={classes.patientLeft}>
								{/* images */}
								<div className={`${classes.patientLeft} image_box_block`}>
									{isMobile ? (
										<div className={`${classes.mainHeadingBlock} main_heading_block`}>
											<span className='secondary_highlighted_text'>
												After you Register as a Doctor,
											</span>
											<br /> You are visible on ezDOC
											<br />
										</div>
									) : (
										<span className='caption_text secondary_highlighted_text'>
											Patient's view
										</span>
									)}
									<img
										className={`${classes.patientImgHand} patient_img_hand`}
										src={patientMobile}
										alt='patients-image'
										align='top'
									/>
								</div>
							</Grid>
							{/* text */}
							<Grid item xs={12} sm={12} md={7} lg={7} className={classes.patientRight}>
								<div className='text_box_block'>
									{!isMobile ? (
										<div className={`${classes.mainHeadingBlock} main_heading_block`}>
											<span className='secondary_highlighted_text'>
												After you Register as a Doctor,
											</span>
											<br /> You are visible on ezDOC
											<br />
											<span className=''>
												Patients can find you & book appointments
											</span>
										</div>
									) : (
										<div className={`${classes.mainHeadingBlock} main_heading_block`}>
											<span className=''>
												Patients can find you &<br /> book appointments
											</span>
										</div>
									)}
									<div className='bullet_point_block'>
										{/* list headline */}
										<span className='bullet_point_heading'>
											Benefits for your Patients
										</span>
										{/* list items */}
										<Box component='div'>
											<BenefitsListCard cardType='patient' />
										</Box>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			</Box>

			{/* dispensary_view container */}

			<Box component='div' className='dispensary_row_box row_box'>
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={1} justifyContent='space-evenly'>
							<Grid item xs={12} sm={12} md={5} lg={5} className={classes.dispensaryLeft}>
								{/* images */}
								<div className='image_box_block'>
									{isMobile ? (
										<div className={`${classes.mainHeadingBlock} main_heading_block`}>
											<span className='secondary_highlighted_text'>
												When patients book appointments,
											</span>
											<br />
											<span className=''>they are visible to you immediately</span>
										</div>
									) : (
										<span className='caption_text secondary_highlighted_text'>
											Dispensary's view
										</span>
									)}
									<img
										className={`${classes.dispensaryImgHand} dispensary_img_hand`}
										src={dispensaryMobile}
										alt='dispensary-image'
										align='top'
									/>
								</div>
							</Grid>
							{/* text */}
							<Grid item xs={12} sm={12} md={7} lg={7} className={classes.dispensaryRight}>
								<div className='text_box_block'>
									{!isMobile ? (
										<div className={`${classes.mainHeadingBlock} main_heading_block`}>
											<span className='secondary_highlighted_text'>
												When patients book appointments,
											</span>
											<br />
											<span className=''>they are visible to you immediately</span>
										</div>
									) : null}

									<div className='bullet_point_block'>
										{/* list headline */}
										<span className='bullet_point_heading'>
											BENEFITS FOR DISPENSARIES & DOCTORS
										</span>
										{/* list items */}
										<Box component='div'>
											<BenefitsListCard cardType='dispensary' />
										</Box>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			</Box>

			{/* googleapps container */}
			<Box component='div' className='googleapps_row_box row_box'>
				<Container className={classes.root} maxWidth='lg' disableGutters={false} component='div'>
					<div className='content_box'>
						<Grid container spacing={2} justifyContent='center'>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<div className='left_block'>
									<Grid container spacing={2} justifyContent='center'>
										<Grid item xs={12}>
											<div className='heading_box'>For Patients</div>
										</Grid>
										<Grid item xs={12}>
											<div className='logo_box'>
												<Grid
													container
													spacing={1}
													justifyContent='center'
													className={`${classes.logoBoxIcons} logo_box_icons`}>
													<Box
														component='div'
														className={`${classes.iconTop} icon_top default`}>
														<img
															src={defaultLogo}
															className=''
															alt='mobile-gallery-logo'
														/>
													</Box>

													<Box
														component='div'
														className={`${classes.iconBottom} icon_bottom default`}>
														<p className='tags'>
															Price:{' '}
															<span className='secondary_highlighted_text'>
																FREE
															</span>
														</p>
														{/* google play store link */}
														<a
															href='https://play.google.com/store/apps/details?id=com.enqbator.ezdoc'
															target='_blank'>
															<img
																src={googlePlayLogo1}
																className={classes.googleImageLink}
																alt='mobile-googleplay-logo'
																align='top'
															/>
														</a>
														{/* apple store link */}
														<a
															href='https://apps.apple.com/lk/app/ezdoc/id1524014547'
															target='_blank'>
															<img
																src={appleStoreLogo1}
																className={classes.appleImageLink}
																alt='mobile-applestore-logo'
																align='top'
															/>
														</a>
													</Box>
												</Grid>
											</div>
										</Grid>
									</Grid>
								</div>
							</Grid>
							{/* end left */}
							{/* Right Block */}
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<div className='right_block'>
									<Grid container spacing={2} justifyContent='center'>
										<Grid item xs={12}>
											<div className='heading_box'>For Doctors & Dispensaries</div>
										</Grid>
										<Grid item xs={12}>
											<div className='logo_box'>
												<Grid
													container
													spacing={1}
													justifyContent='center'
													className={`${classes.logoBoxIcons} logo_box_icons`}>
													<Box
														component='div'
														className={`${classes.iconTop} icon_top pro`}>
														<img
															src={proLogo}
															className=''
															alt='mobile-gallery-logo'
														/>
													</Box>

													<Box
														component='div'
														className={`${classes.iconBottom} icon_bottom pro`}>
														<p className='tags'>
															Price:{' '}
															<span className='secondary_highlighted_text'>
																FREE
															</span>
														</p>
														{/* google play store link */}
														<a
															href='https://play.google.com/store/apps/details?id=com.enqbatorezdocpro'
															target='_blank'>
															<img
																src={googlePlayLogo1}
																className={classes.googleImageLink}
																alt='mobile-googleplay-logo'
																align='top'
															/>
														</a>
														{/* apple store link */}
														<a
															href='https://apps.apple.com/us/app/ezdoc-pro/id1533280033'
															target='_blank'>
															<img
																src={appleStoreLogo1}
																className={classes.appleImageLink}
																alt='mobile-applestore-logo'
																align='top'
															/>
														</a>
													</Box>
												</Grid>
											</div>
										</Grid>
									</Grid>
								</div>
							</Grid>
							{/* end right */}
						</Grid>
					</div>
				</Container>
			</Box>
		</Fragment>
	)
}

function mapStateToProps(state) {
	return {}
}

function mapDispatchToProps(dispatch) {
	return {
		dispensaryActions: bindActionCreators(dispensaryActions, dispatch),
		doctorActions: bindActionCreators(doctorActions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
