import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container, Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'

import { NAVBAR_ROUTES } from 'constants/NavConstants'
import ViewBox from 'containers/portalweb/components/ViewBox'
import DispensaryViewBox from './DispensaryViewBox'

const useStyles = makeStyles(theme => ({
	root: {
		flex: 1,
		//border: '1px solid aliceblue',
		marginBottom: '1rem'
		//backgroundColor:'#fafafa'
	},
	viewBoxOuter: {
		width: '100%',
		padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
		[theme.breakpoints.down('xs')]: {
			//
		}
	}
}))

const PortalTableViewBox = props => {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const { data, history } = props

	function onPressedRouteTo(routeTo) {
		if (routeTo === 'Doctor(s)') {
			history.push(NAVBAR_ROUTES.DOCTOR_SIGNUP_FORM)
		} else if (routeTo === 'Dispensary Staff(s)') {
			history.push(NAVBAR_ROUTES.DESPENSARY_STAFF_SIGNUP_FORM)
			//window.alert('DESPENSARY_STAFF_SIGNUP_FORM')
		}
	}

	function editDispensary(dispensary) {
		// console.log('edit dispensary', dispensary)
		history.push({ pathname: NAVBAR_ROUTES.DESPENSARY_SIGNUP_FORM, dispensary: dispensary })
	}

	function editDoctor(doctor) {
		// console.log('edit doctor', doctor)
		history.push({ pathname: NAVBAR_ROUTES.DOCTOR_SIGNUP_FORM, doctor: doctor })
	}

	function editStaff(staff) {
		// console.log('edit staff', staff)
		history.push({ pathname: NAVBAR_ROUTES.DESPENSARY_STAFF_SIGNUP_FORM, staff: staff, edit: true })
	}

	return (
		<div className={classes.root}>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<div className={classes.viewBoxOuter}>
						<DispensaryViewBox
							boxHeading='Dispensary'
							data={data.dispensaryList}
							isButton={false}
							onPress={editDispensary}
						/>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className={classes.viewBoxOuter}>
						<ViewBox
							boxHeading='Doctor(s)'
							data={data.doctorList}
							btnLabel='Add a Docotr'
							onPress={editDoctor}
							routeTo={onPressedRouteTo}
						/>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className={classes.viewBoxOuter}>
						<ViewBox
							boxHeading='Dispensary Staff(s)'
							data={data.staffList}
							btnLabel='Add a Staff'
							onPress={editStaff}
							routeTo={onPressedRouteTo}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}

export default PortalTableViewBox
