import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import {
	Box,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button
} from '@material-ui/core'
import { ArrowBack, Close } from '@material-ui/icons'

import Widgets from 'components/widgets'
import * as appActions from '../../store/AppStore/appActions'
import * as authActions from '../../containers/forms/auths/redux/actions'
import logo from 'images/ezdoc_logo_lg.png'
import { NAVBAR_ROUTES } from 'constants/NavConstants'

const styles = theme => ({
	root: {
		flex: 1,
		'& .MuiBackdrop-root': {
			background: '#0E2C39'
			//opacity: 0.9 TODO rgba
		},
		'& .MuiBox-root': {
			background: '#EBFFFB 0% 0% no-repeat padding-box'
		},
		'& .MuiDialogActions-root': {
			background: '#FFFFFF 0% 0% no-repeat padding-box'
		},
		'& .MuiDialogContent-root': {
			background: '#FFFFFF 0% 0% no-repeat padding-box'
		}
	},
	errorTopBox: {
		//border: '1px dotted #003',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: `${theme.spacing(0.3)}px ${theme.spacing(0.5)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(0.3)}px ${theme.spacing(0)}px`
		}
	},
	errorTopLogo: {
		maxWidth: '50px',
		maxHeight: '50px',
		boxSizing: 'border-box'
	},
	logo: {
		width: '100%',
		height: '100%',
		transform: 'scale(0.8)',
		[theme.breakpoints.down('xs')]: {
			transform: 'scale(0.7)'
		}
	},
	errorTopTitle: {
		textAlign: 'left',
		paddingLeft: theme.spacing(2),
		width: '100%',
		fontFamily: 'Roboto Condensed,sans-serif',
		color: '#0E2C39',
		fontSize: 'clamp(17px,1.8vw, 40px)',
		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(0)
		}
	},
	errorTopClose: {
		color: '#39CFBA',
		marginTop: theme.spacing(0.7),
		marginRight: theme.spacing(0.5),
		[theme.breakpoints.down('xs')]: {
			//paddingLeft: theme.spacing(0),
		}
	},
	errorContentBox: {
		backgroundColor: '#FFF',
		width: '100%', // width: 620px;
		padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`
		}
	},
	errorContentText: {
		fontFamily: 'Roboto Medium,sans-serif',
		color: '#0E2C39',
		fontSize: 'clamp(14px,1.3vw, 25px)',
		[theme.breakpoints.up('xl')]: {
			//padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
			//fontSize: '24px',
		}
	},
	errorFooterBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		margin: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(5)}px ${theme.spacing(2)}px`
	}
})

class ErrorModal extends React.Component {
	onPressClose = () => {
		this.props.appActions.hideError()
	}

	navigate = action => {
		const { history } = this.props
		switch (action) {
			case 'DISMISS':
				this.props.appActions.hideError()
				break
			case 'NAVIGATE_TO_LOGIN':
				history.push(NAVBAR_ROUTES.OWNER_LOGIN)
				this.props.appActions.hideError()
				break
			case 'NAVIGATE_TO_PORTAL':
				history.push(NAVBAR_ROUTES.OWNER_PORTAL_WEB)
				this.props.appActions.hideError()
				break
			case 'NAVIGATE_TO_OTP':
				this.props.appActions.hideError()
				this.props.authActions.updateFormStep(2)
				break
			case 'LOGOUT':
				this.props.authActions.signOut()
				this.props.appActions.hideError()
				history.push(NAVBAR_ROUTES.OWNER_LOGIN)
				break
			default:
				this.props.appActions.hideError()
		}
	}

	render() {
		const { classes, error } = this.props

		const { show, data } = error

		return (
			<Dialog
				fullWidth={true}
				maxWidth='sm'
				open={show}
				onClose={this.onPressClose}
				aria-labelledby='dialog-error-dialog'
				className={classes.root}>
				<Box component='div' className={classes.errorTopBox}>
					<div className={classes.errorTopLogo}>
						<img className={classes.logo} src={logo} alt='ezdoc-logo' />
					</div>
					<div className={classes.errorTopTitle}>{data.header}</div>
					<div className={classes.errorTopClose}>
						<span onClick={this.onPressClose}>
							<Close />
						</span>
					</div>
				</Box>
				{/* error-heading-end */}

				<DialogContent>
					<div className={classes.errorContentBox}>
						<DialogContentText>
							<span className={classes.errorContentText}>{data.body}</span>
						</DialogContentText>
					</div>
				</DialogContent>
				{/* footer action */}
				<DialogActions>
					<div className={classes.errorFooterBox}>
						{/* <Button variant="contained" color="primary">
                           
                        </Button> */}
						<Widgets.ButtonBox
							type='button'
							disabled={false}
							label={data.button_text}
							size='large'
							onClick={() => this.navigate(data.action)}
						/>
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}

function mapStateToProps(state) {
	return {
		error: state.appReducer.error
	}
}

function mapDispatchToProps(dispatch) {
	return {
		appActions: bindActionCreators(appActions, dispatch),
		authActions: bindActionCreators(authActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ErrorModal))
