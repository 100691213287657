import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { withStyles } from '@material-ui/core/styles'
import { Box, Grid, Container } from '@material-ui/core'

import * as contactActions from './redux/actions'

import Validators from 'utils/Validators'
import ContactForm from 'containers/forms/contactus/components/ContactForm'
import ContactSuccessMessage from 'containers/forms/contactus/components/ContactSuccessMessage'
import emailIcon from 'images/contact_imgs/email.svg'
import telephoneIcon from 'images/contact_imgs/telephone.svg'
import locationIcon from 'images/contact_imgs/location.svg'

const withMediaQuery = (...args) => Component => props => {
	const mediaQuery = useMediaQuery(...args)
	return <Component mediaQuery={mediaQuery} {...props} />
}

const MIN_HEIGHT = 'calc(100vh - 10.4rem)'
const IFRAM_URL =
	'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31640.582992365267!2d80.254432!3d7.567034149999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae256d24ba0a2ad%3A0x7df2dae74fb168a1!2sKahanthota%20Rd%2C%20Malabe!5e0!3m2!1sen!2slk!4v1607760065844!5m2!1sen!2slk'

const styles = theme => ({
	root: {
		flex: 1,
		minHeight: 'calc(100vh - 11.7em)',
		boxSizing: 'border-box',
		[theme.breakpoints.between('md', 'lg')]: {
			zoom: '0.75',
			//minHeight: 'calc(100vh + 1.33rem)'
			minHeight: 'calc(100vh + 3.22rem)'
		}
	},

	wrapperBox: {
		// border: '1px dotted pink',
		width: '100%',
		height: '100%',
		padding: 'calc(1rem + 1%) calc( 0.5rem + 1% )',
		[theme.breakpoints.down('sm')]: {
			height: '90%'
		}
	},

	/**
	 * ..................................
	 * left main
	 * ..................................
	 */
	contactInfo: {
		boxSizing: 'border-box',
		minHeight: MIN_HEIGHT,
		flexGrow: 1,
		[theme.breakpoints.down('sm')]: {
			minHeight: '35vh',
			padding: `${theme.spacing(0.5)}px ${theme.spacing(0)}px`
		}
	},
	addressInfo: {
		padding: 'calc(2rem + 1%) calc( 1rem + 1% )',
		[theme.breakpoints.down('xs')]: {
			padding: 'calc(0rem + 0.5%) calc( 0.2rem + 0.5% )'
		},
		[theme.breakpoints.down('md')]: {
			padding: 'calc(0rem + 0.5%) calc( 0rem + 0.5% )'
		}
	},
	mapInfoBox: {
		height: '100%'
		// [theme.breakpoints.between('md', 'lg')]: {
		//     //minHeight: 'calc(100vh + 3.2rem)',
		//     height: '105%',
		// },
	},
	mapInfo: {
		border: 'none',
		// minHeight: 'calc(100vh - 180px)',
		height: 'calc(100% + calc(100% * .75))',
		[theme.breakpoints.down('sm')]: {
			minHeight: '42vh'
		},
		[theme.breakpoints.between('md', 'lg')]: {
			height: 'calc(100% + calc(100% * .75))'
		}
	},

	headingBox: {
		//border: '1px dotted green',
		width: '100%',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`
	},

	headingBoxMainText: {
		color: '#0E2C39',
		fontSize: 'clamp(20px, calc(1vw + 0.7rem), 30px)',
		fontFamily: 'Roboto Medium ,sans-serif'
	},
	headingBoxSubText: {
		color: '#0E2C39',
		fontSize: 'clamp(16px, calc(1vw + 0.1rem), 20px)',
		fontFamily: 'Roboto Light ,sans-serif',
		padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`
	},

	addressBox: {
		// border: '1px dotted green',
		width: '100%'
	},
	infoGroupBox: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		margin: `${theme.spacing(7)}px ${theme.spacing(0)}px`,
		padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
		[theme.breakpoints.down('xs')]: {
			margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
			padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`
		},
		[theme.breakpoints.down('sm')]: {
			margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
			padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`
		}
	},

	infoText: {
		//border: '1px dotted pink',
		padding: `${theme.spacing(0)}px ${theme.spacing(7)}px`,
		color: '#000000',
		fontSize: 'clamp(16px, calc(1vw + 0.3rem), 25px)', // 20px
		fontFamily: 'Roboto Medium ,sans-serif',
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`
		}
	},

	infoImg: {
		transform: 'scale(1)',
		//border: '1px dotted pink',
		[theme.breakpoints.down('xs')]: {
			transform: 'scale(0.7)'
		}
	},

	/**
	 * .........................................................................
	 * Right main
	 * .........................................................................
	 */
	contactForm: {
		boxSizing: 'border-box',
		minHeight: MIN_HEIGHT,
		flex: 1,
		//TODO backgroundColor: '#FEF9E9'
		[theme.breakpoints.between('md', 'lg')]: {
			height: 'calc(100% + calc(100% * .75))',
			'&::before': {
				top: 'calc(1rem + 9%)'
			},
			'&::after': {
				top: 'calc(1rem + 6%)'
			}
		}
	},
	formTopBox: {
		minHeight: '3rem',
		width: '100%',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`
	},
	headingHiddenBoxSubText: {
		//border: '1px dotted red',
		width: '100%',
		display: 'none',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			color: '#0E2C39',
			fontSize: 'clamp(18px, calc(1vw + 0.2rem), 18px)', // 20px
			fontFamily: 'Roboto Medium ,sans-serif'
		}
	},

	formBottomBox: {
		//border: '1px dotted green',
		width: '100%',
		height: '100%',
		padding: `${theme.spacing(0)}px ${theme.spacing(5)}px`,
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(0)}px ${theme.spacing(0)}px`
		}
	}
})

class ContactPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			errors: {}
		}
	}

	componentDidMount() {
		const { contactActions, history } = this.props
		const { location } = history
		contactActions.updateFormStep(1)
	}

	componentDidUpdate(prevProps) {
		const { contactState, contactActions } = this.props
		const { step } = contactState

		let nextStep = step === 2 ? step : step + 1

		const prevResponse = prevProps.contactState.contactUsResponse
		const currentResponse = contactState.contactUsResponse

		if (currentResponse !== prevResponse) {
			if (currentResponse.message === 'OK') {
				contactActions.updateFormStep(nextStep)
			}
		}
	}

	validator = () => {
		const { contactState } = this.props
		const { errors } = this.state

		const { formInputs } = contactState
		const { fullname, email, message } = formInputs
		errors.fullname = Validators.required(fullname)
		errors.email = Validators.required(email) || Validators.validateEmail(email)
		errors.message = Validators.required(message)

		this.setState({ ...errors })

		return Object.values(errors).every(err => err === '' || err === undefined)
	}

	handleInputChange = e => {
		const { contactActions } = this.props
		const { name, value } = e.target
		contactActions.onChangeField(name, value)
	}

	handleSubmit = e => {
		const { contactActions, contactState } = this.props
		const { step, formInputs } = contactState
		const { fullname, email, message } = formInputs

		e.preventDefault()
		if (this.validator()) {
			const data = {
				email: email,
				full_name: fullname,
				message: message
			}
			contactActions.submitContactUsData(data)
			// contactActions.updateFormStep(nextStep);
		}
	}

	resetForm = e => {
		e.preventDefault()
		const { contactActions } = this.props
		contactActions.resetForm()
	}

	onClosed = e => {
		e.preventDefault()
		const { contactActions } = this.props
		contactActions.resetForm()
		contactActions.updateFormStep(1)
	}

	render() {
		const { classes, contactState, mediaQuery } = this.props
		const { errors } = this.state

		const { step, formInputs } = contactState
		const { fullname, email, message } = formInputs
		const data = { fullname, email, message }
		const isMobile = mediaQuery

		return (
			<Container maxWidth={false} disableGutters={true} component='div'>
				<Grid container direction='column' justifyContent='flex-start' alignItems='stretch'>
					<div className={classes.root}>
						<Grid container spacing={0}>
							{/* Left::Contact Info */}
							<Grid item xs={12} sm={12} md={7} lg={7}>
								<div className={classes.contactInfo}>
									<Grid container spacing={0}>
										<Grid item xs={4}>
											<div className={classes.mapInfoBox}>
												<iframe
													src={IFRAM_URL}
													width='100%'
													height='100%'
													className={classes.mapInfo}></iframe>
											</div>
										</Grid>
										<Grid item xs={8}>
											<div className={classes.addressInfo}>
												<Box
													component='div'
													display='flex'
													flexDirection='column'
													justifyContent='flex-start'
													alignItems='flex-start'
													className={classes.wrapperBox}>
													<div className={classes.headingBox}>
														<span className={classes.headingBoxMainText}>
															Let’s get in touch,
														</span>
														<span
															className={`${classes.headingBoxSubText} heading_box_sub_text`}>
															{' '}
															Send us a message. We will be glad to answer your
															enquiries
														</span>
													</div>
													<div className={classes.addressBox}>
														<Box component='div' className={classes.infoGroupBox}>
															<img
																src={telephoneIcon}
																alt='telphone'
																className={classes.infoImg}
															/>
															<span className={classes.infoText}>
																+94 77 706 2733
															</span>
														</Box>
														<Box component='div' className={classes.infoGroupBox}>
															<img
																src={emailIcon}
																alt='email'
																className={classes.infoImg}
															/>
															<span className={classes.infoText}>
																info@ezdoc.lk
															</span>
														</Box>
														<Box component='div' className={classes.infoGroupBox}>
															<img
																src={locationIcon}
																alt='location'
																className={classes.infoImg}
															/>
															<span className={classes.infoText}>
																29/A/1 Kahanthota Road,
																<br />
																Malabe.
																<br />
																SRI LANKA
															</span>
														</Box>
													</div>
												</Box>
											</div>
											{/* addressInfo END */}
										</Grid>
									</Grid>
								</div>
							</Grid>
							{/* Right::Form */}
							<Grid item xs={12} sm={12} md={5} lg={5}>
								<div className={`${classes.contactForm} contact_form`}>
									<Box
										component='div'
										display='flex'
										flexDirection='column'
										justifyContent='flex-start'
										alignItems='flex-start'
										className={classes.wrapperBox}>
										{step === 1 && isMobile ? (
											<div className={classes.formTopBox}>
												<Box
													component='div'
													display='flex'
													flexDirection='column'
													justifyContent='flex-start'
													alignItems='flex-start'>
													<span
														className={`${classes.headingHiddenBoxSubText} heading_box_sub_text_mobile`}>
														Send us a message.
														<br /> We will be glad to answer your enquiries
													</span>
												</Box>
											</div>
										) : null}
										<div className={classes.formBottomBox}>
											{step === 1 ? (
												<ContactForm
													data={data}
													errors={errors}
													handleSubmit={this.handleSubmit}
													handleInputChange={this.handleInputChange}
													resetForm={this.resetForm}
												/>
											) : (
												<ContactSuccessMessage onPressed={this.onClosed} />
											)}
										</div>
									</Box>
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Container>
		)
	}
}

function mapStateToProps(state) {
	return {
		contactState: state.contactReducer
	}
}

function mapDispatchToProps(dispatch) {
	return {
		contactActions: bindActionCreators(contactActions, dispatch)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withMediaQuery('(max-width:425px)')(ContactPage)))
