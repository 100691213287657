import React, { useState, useEffect, Fragment } from 'react'
import { Box, Container } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import FormToolbar from 'containers/forms/components/FormToolbar'

import useMediaQuery from '@material-ui/core/useMediaQuery'

const withMediaQuery = (...args) => Component => props => {
	const mediaQuery = useMediaQuery(...args)
	return <Component mediaQuery={mediaQuery} {...props} />
}

const useStyles = makeStyles(theme => ({
	formHeaderBox: {
		boxSizing: 'border-box',
		minHeight: '10rem'
	},
	primaryHeading: {
		//border: '1px dotted #333',
		display: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		//fontSize: 'clamp(24px,2.5vw,50px)',
		fontSize: 'clamp(24px, calc(1vw + 1.5rem), 50px)',
		fontFamily: 'Roboto Condensed, sans-serif',
		color: '#0e2c39',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			fontSize: '24px'
		}
	},
	secondaryHeading: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		//fontSize: 'clamp(16px,2.4vw,24px)',
		fontSize: 'clamp(16px, calc(1vw + 0.8rem), 24px)',
		fontFamily: 'Roboto Bold ,sans-serif',
		color: '#0e2c39',
		background: '#C1FDF1',
		//padding: '5px 5px 5px 30px',
		paddingTop: `${theme.spacing(0.7)}px`,
		paddingBottom: `${theme.spacing(0.6)}px`,
		paddingLeft: `${theme.spacing(6)}px`,
		margin: `${theme.spacing(7)}px ${theme.spacing(0)}px`,
		[theme.breakpoints.down('xs')]: {
			paddingLeft: `${theme.spacing(4)}px`,
			marginTop: '30px'
		}
	}
}))

const FormHeader = props => {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const { show, mainHeading, subHeading = null, exitForm, prevStep, stepper, prevArrow = true } = props

	return show ? (
		<Box component='div' className={classes.formHeaderBox}>
			<FormToolbar
				prevArrow={prevArrow}
				stepper={stepper}
				mainHeading={mainHeading}
				exitForm={exitForm}
				prevStep={prevStep}
			/>
			<Box component='div' className={classes.primaryHeading}>
				{mainHeading}
			</Box>
			{subHeading ? (
				<Box component='div' className={classes.secondaryHeading}>
					{subHeading}
				</Box>
			) : null}
		</Box>
	) : (
		<FormToolbar
			prevArrow={prevArrow}
			stepper={null}
			mainHeading={null}
			exitForm={exitForm}
			prevStep={prevStep}
		/>
	)
}

export default withMediaQuery('(max-width:425px)')(FormHeader)
