import React, { Component, Fragment, useState } from 'react'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
    Box,
    Container,
    Toolbar,
    AppBar, Typography,
    Button
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import brandLogo from '../../../images/ezdoc_logo_lg.png'
import Controls from 'containers/forms/components/controls'
import Widgets from 'components/widgets'
import * as kioskActions from './redux/actions';
import * as loginActions from '../auths/redux/actions'
// import { APIStatusCode, AppConstants } from '../../../constants';
import PropTypes from 'prop-types';
import FormControlsLayout from 'containers/forms/components/FormControlsLayout'
import FormControlEnd from 'containers/forms/components/FormControlEnd'
import { AppointmentsConstants } from 'constants/AppointmentsConstants'
import appointmentsReducer from './redux/reducer'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../../graphql/subscriptions'
// import Moment from 'moment';
import moment from 'moment-timezone'
import DefaultNumberKeyboard from 'containers/forms/components/shared/DefaultNumberKeyboard'
import navigateToKioskMobileNumberSagaNum from './saga/Sagas'


const styles = theme => ({
    root: {
        flexGrow: 1,

        [theme.breakpoints.up('lg')]: {
            maxWidth: '1300px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        // TODO zoom
        [theme.breakpoints.between('md', 'lg')]: {
            //backgroundColor:'red',
            zoom: '0.87'
        },

    },

    navbarStyle: {
        background: '#ebfffb',
        color: '#0e2c39'
    },
    brandLogoIcon: {
        width: '50px',
        height: '50px',
        marginLeft: '-1em',
        [theme.breakpoints.down('sm')]: {
            //flexGrow: 1,
        }
    },
    brandLogoIcon2: {
        width: '100px',
        height: '100px',
        marginLeft: '-1em',
        [theme.breakpoints.down('sm')]: {
            //flexGrow: 1,
        }
    },
    title: {
        fontSize: 35,
        color: '#159782',
        fontWeight: 'bold'
    },
    headerOptions: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-evenly'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        marginTop: theme.spacing(5)
    },
    containerThankyou: {
        marginTop: theme.spacing(10)
    },
    gridSpacing: {
        marginTop: theme.spacing(2)
    },
    formLabelWrapperRight: {
        textAlign: 'right',
        marginTop: theme.spacing(2)
    },
    formLabelWrapperLeft: {
        textAlign: 'left',
        marginTop: theme.spacing(2)
    },
    formLabel: {
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: '25px',
        width: 400,
        paddingTop: 10,
        paddingBottom: 10,
        marginRight: 10,
    },
    formLabelThanks: {
        fontSize: 30,
        fontWeight: 'bold',
        lineHeight: '30px',
    },
    textFiled: {
        width: '100%',
        border: '2px solid #159782',
        color: 'black',
        '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
            '& .MuiOutlinedInput-input': {
                fontFamily: 'Roboto Medium,sans-serif',
                //fontSize: 'clamp(13px,2vw,20px)'
            },

            '& fieldset': {
                borderColor: 'none',
                border: 'none'
            },
            '&:hover fieldset': {
                //borderColor: themeGreenColor,
                border: 'none'
            },
            '&.Mui-focused fieldset': {
                //borderColor: mingColor, * when focus feild
                borderColor: 'none',
                border: 'none'
            }
        },
        "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "blue"
        }
    },
    numberWrapper: {
        background: '#0E2C39',
        width: 'auto',
        height: 150,
        borderRadius: 10,
        color: '#ffffff',
        textAlign: 'center',
        fontSize: 100,
    },
    backButton: {
        width: 100,
        height: 40,
        fontSize: 20,
        borderRadius: 10,
        color: '#0E2C39',
        backgroundColor:'#C1C1C1',
        textTransform: 'capitalize',
        //borderColor:'#707070',
        //border:'4px solid',

    },
    closeButton: {
        background: '#707070',
        color: '#ffffff',
        fontSize: 25,
        textTransform: 'capitalize'
    },
    countTimer: {
        fontWeight: '400',
        fontSize: '15px',
        marginLeft: '5px',
    },
    kioskFooter: {
        background: '#0E2C39',
        minHeight: 60,
        textAlign: 'center',
        color: '#ffffff',
        fontWeight: '500',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        padding: '7px 0'
    },
    footerText: {
        fontSize: 30,
    },
    container: {
        maxWidth: 1380
    },
    formLabelSinhala: {
        fontSize: 28,
        fontWeight: 'bold',
        lineHeight: '25px',
        width: 400,
        paddingTop: 10,
        paddingBottom: 10,
        marginRight: 70,

    },
})

class KioskNumberForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mobileNumber: '07',
            slotAvailable: this.props.appointmentState.appointments.length == 0 ? false : true
        }
        const { match } = this.props

    }

    onPressBookAndCheckIn = () => {
        this.bookAppointment(true);
    };

    bookAppointment = (mobileNumber) => {
        const { doctorId, dispensaryId, displayName } = this.state;
        console.log(">>>> display name :::: ", displayName)
        const firstName = this.props.appointmentState.firstName.payload
        var tendigitrandomnumber = Math.floor(Math.random() * 1000000000);
        mobileNumber = '947' + mobileNumber
        const payload = {
            firstName: firstName,
            mobileNumber: mobileNumber.length == 11 ? mobileNumber : `0${tendigitrandomnumber}`,
            bookingType: AppointmentsConstants.booking_type.WALK_IN,
            doctorId: doctorId,
            patient: firstName,
            symptom: '',
            dispensaryId: dispensaryId,
            familyMember: null,
            autoCheckedIn: true,
            kiosk: true,
            displayName: displayName,
        };

        this.props.appointmentsActions.requestBookAppointment(payload);
    };

    componentDidMount(prevProps) {
        this.setState(
                {
                    doctorId: this.props.appointmentState.doctors[0]['id'],
                    dispensaryId: this.props.loginState.currentUserInfo.attributes['email'],
                    displayName: this.props.appointmentState.doctors[0]["display_name"],

                }
            )
    }

    getDoctorsByDispensary = (payload) => {
        this.props.appointmentsActions.requestDoctorsByDispensary(payload);
    };

    refreshTodayApoimentsForDispencery = (payload) => {

        this.props.appointmentsActions.requestAppointments(payload);
    }

    handleInputChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }


    onPressRegwalkingPatient = e => {
        const { doctor, slot, bookingType, props, state } = this;
        const { symptom } = state;
        this.onPressBookAndCheckIn();
    };

    CHARACTER_LIMIT = 10;

    render() {

        const { classes } = this.props;
        //const { errors } = this.state

        return (
            <>
                <AppBar position='fixed' className={classes.navbarStyle}>
                    <Container maxWidth='lg' disableGutters={false}>
                        <Toolbar className={classes.toolbar}>
                            <Box component='span'>
                                <img src={brandLogo} className={classes.brandLogoIcon} alt='Logo' />
                            </Box>
                            <div className={classes.headerOptions}>
                                <Box>
                                    <Typography className={classes.title}>For SMS</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.title}>SMS එවීම සඳහා</Typography>
                                </Box>
                            </div>
                            <Box component='span'>
                                <Button variant="contained" 
                                    className={classes.backButton}  
                                    onClick={this.props.appointmentsActions.navigateToKiosk}>
                                    &#060;&#060;&nbsp;Back
                                </Button>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Container className={classes.container}>

                    <FormControlsLayout onSubmit={this.onPressRegwalkingPatient} >
                        <Grid container>

                            <Grid item xs={12} className={classes.gridSpacing}>
                                <DefaultNumberKeyboard onClickEnter={this.bookAppointment} />
                            </Grid>
                        </Grid>
                    </FormControlsLayout>
                </Container>

            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        appointmentState: state.appointmentsReducer,
        loginState: state.authReducer,
        dispensaryState: state.dispensaryReducer

    }
}

function mapDispatchToProps(dispatch) {
    return {
        appointmentsActions: bindActionCreators(kioskActions, dispatch),
        loginActions: bindActionCreators(loginActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(KioskNumberForm))
